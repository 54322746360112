import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Button from '@material-ui/core/Button';
import ParticlesAnimation from './../particlesAnimation/ParticlesAnimation';
import ModalCalendly from './../modalCalendly/ModalCalendly';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { findDevice } from './../../utils/devices';

import './SectionDemo.scss';

class SectionDemo extends Component  {

  state = {
    showParticles: true,
    open: false,
    scroll: 'paper',
    deviceIs: ''
  }

  deviceIs = null;

  constructor(props) {
    super(props);
    window.addEventListener('resize', () => {
      this.setState({
        deviceIs: findDevice()
      });
    });
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
    this.setState({
      deviceIs: findDevice()
    });
	}

  handleImageLoaded() {
    this.setState({
      showParticles: true
    });
  }

  handleClickOpen () {
    this.setState({
      open: true
    });
  };

  handleClose () {
    this.setState({
      open: false
    });
  };

  render() {
    return (
      <div className="content-demo">
        { this.state.showParticles ? <ParticlesAnimation /> : null }
        <div className="container-info">
          <div
            className="container-devices"
            style={{
              backgroundImage: this.state.deviceIs === 'desktop' ?
              "url('./img/040_mobile-8.png'), url('./img/028_backmobiles.svg')" : "url('./img/043_mobile-8.png')"
            }}
            data-aos="fade-up"
            data-aos-offset={this.state.deviceIs === 'desktop' ? "550" : "200"}
            data-aos-once="true">
          </div>
          <div className="text-center container-info-right">
            <div className="container-info-services text-left">
              <div>
                <img
                  className="img-clock"
                  src="./img/014_timer.svg"
                  data-aos="fade-right"
                  data-aos-once="true"
                  alt="clock"/>
              </div>
              <div
                className="text-services mt-2"
                data-aos="fade-up"
                data-aos-once="true">
                  <Trans i18nKey="sectionDemo.deployEcommerce" />
              </div>
              <div
                className="text-services normal text-blue-light-beluga"
                data-aos="fade-up"
                data-aos-once="true">
                  <Trans i18nKey="sectionDemo.under" /> 
                  <span className="bold">
                    <Trans i18nKey="sectionDemo.days" />
                  </span>
              </div>
              <div
                className="mt-2"
                data-aos="fade-up"
                data-aos-once="true">
                <Button className="btn-demo" variant="outlined" onClick={this.handleClickOpen.bind(this)}>
                  DEMO
                </Button>
                <ModalCalendly
                  open={this.state.open}
                  close={this.handleClose.bind(this)}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } 
}

export default withTranslation()(SectionDemo);