const en = {
    deployEcommerce: 'Deploy ecommerce, <br/>mobile point of sale,<br/>peer 2 peer apps',
    under: 'in under ',
    days: '30 days'
}

const es = {
    deployEcommerce: 'Implementa ecommerce, <br/>punto de venta,<br/>aplicaciones de pago',
    under: 'en menos de ',
    days: '30 días'
}

module.exports = {
    en,
    es
};