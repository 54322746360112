import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import ModalCalendly from './../modalCalendly/ModalCalendly';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './SectionHeader.scss';

class SectionHeader extends Component {

  state = {
    open: false,
    scroll: 'paper'
  }

  componentDidMount() {
    AOS.init({
      duration: 800
    });
  }

  handleClickOpen () {
    this.setState({
      open: true
    });
  };

  handleClose () {
    this.setState({
      open: false
    });
  };
  
  render() { 
    return (
      <div className="section-header" id="header">
        <div
          className="section-background"
          style={{
            backgroundImage: 'url("./img/001_banner_principal.jpg")'
          }}
          >
          <div className="ml-5">
            <div
              className="section-text-left"
              data-aos="fade-up"
              data-aos-once="true">
                <Trans i18nKey="sectionHeader.launchYourDigitalBank" />
            </div>
            <div
              className="mt-5"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-once="true">
              <div className="btn btn-blue-dark btn-header" onClick={this.handleClickOpen.bind(this)}>
                <Trans i18nKey="sectionHeader.requestDemo" />
              </div>
              <ModalCalendly
                open={this.state.open}
                close={this.handleClose.bind(this)}
                />
            </div>
          </div>
        </div>
      </div>
    )
  } 
}

export default withTranslation()(SectionHeader);