import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

import './ModalCalendly.scss';

class ModalCalendly extends Component {

  state = {
    open: false,
    scroll: 'paper'
  }

  static getDerivedStateFromProps(props) {
    return {
      open: props.open
    };
  }

  loadCalendly() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
    script.id = "calendly-widget";
    head.appendChild(script);
  }

  handleClickOpen () {
    this.setState({
      open: true
    });
  };

  handleClose () {
    this.props.close();
    this.setState({
      open: false
    });
  };
  
  render() {
    this.loadCalendly();
    return (
      <Dialog
        className="modal-calendly"
        open={this.state.open}
        onClose={this.handleClose.bind(this)}
        scroll={'paper'}
        maxWidth={'lg'}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogContent dividers={true}>
          <div id="container-calendly" className="container-calendly">
            <div className="iconLoading">
              <Fade
                in={true}
                >
                  <CircularProgress />
              </Fade>
            </div>
            <div
              className="calendly-inline-widget"
              data-url="https://calendly.com/belugapayalex"
              style={{ maxWidth: '1024px', width: '100%', height:'75vh' }}></div>
          </div>
        </DialogContent>
      </Dialog>
    );
  } 
}

export default withTranslation()(ModalCalendly);