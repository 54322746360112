import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './InstantDeposits.scss';

class InstantDeposits extends Component  {
  
  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="instant-deposits">
        <div className="container-getpaid">
          <div className="container-info">
            <div
              className="title text-right light"
              data-aos="fade-up"
              data-aos-once="true">
              <span className="semi-bold">
                <Trans i18nKey="instantDeposits.getPaid"/>
              </span>
              <Trans i18nKey="instantDeposits.minutes"/>
              <br/>
              <Trans i18nKey="instantDeposits.noWaiting"/>
            </div>
          </div>
          <div
            className="content-imageGetpaid text-center"
            data-aos="fade-up"
            data-aos-once="true">
            <img className="image-app" src="./img/041_mpos-8.png" alt="mpos"/>
          </div>
        </div>
      </div>
    )
  } 
}

export default InstantDeposits;
