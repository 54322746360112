import React, { Component } from 'react';

import SectionHeader from '../../generals/sectionHeader/SectionHeader';
import SectionTrademarks from '../../generals/sectionTrademarks/SectionTrademarks';
import SectionDemo from '../../generals/sectionDemo/SectionDemo';
import SectionWhatWeDo from '../../generals/sectionWhatWeDo/SectionWhatWeDo';
import SectionSteps from '../../generals/sectionSteps/SectionSteps';
import SectionDownload from '../../generals/sectionDownload/SectionDownload';
import SectionContact from '../../generals/sectionContact/SectionContact';

class Home extends Component {
  render() {
    return (
      <div>
        <SectionHeader />
        <SectionTrademarks />
        <SectionDemo />
        <SectionWhatWeDo />
        <SectionSteps />
        <SectionContact>
          <SectionDownload />
        </SectionContact>
      </div>
    )
  } 
}

export default Home;