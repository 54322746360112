import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Container from '@material-ui/core/Container';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './SectionWhatWeDo.scss';

class SectionWhatWeDo extends Component  {
  
  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="content-what-we-do">
        <div className="gradiente-blue-green text-center position-relative">
          <Container maxWidth="xl">
            <div
              className="title-section"
              data-aos="fade-up"
              data-aos-once="true">
              <span className="light">
                <Trans i18nKey="sectionWhatWeDo.what"/>
              </span>
              <span className="semi-bold">
              <Trans i18nKey="sectionWhatWeDo.we"/>
              </span>
            </div>
            <div className="mt-3">
              <img
                className="icon-world"
                src="./img/015_latinamerica.svg"
                alt="iconWorld"
                data-aos="fade-up"
                data-aos-once="true"/>
            </div>
            <div className="mt-3 container-text-banking">
              <div
                className="light"
                data-aos="fade-up"
                data-aos-once="true">
                  <Trans i18nKey="sectionWhatWeDo.banking"/>
              </div>
              <div
                className="normal"
                data-aos="fade-up"
                data-aos-once="true">
                  <Trans i18nKey="sectionWhatWeDo.make"/>
              </div>
            </div>
            <div className="mt-5 container-text-subtitle">
              <div
                className="semi-bold"
                data-aos="fade-up"
                data-aos-once="true">
                  <Trans i18nKey="sectionWhatWeDo.makeEasy"/>
              </div>
              <div
                className="semi-bold"
                data-aos="fade-up"
                data-aos-once="true">
                  <Trans i18nKey="sectionWhatWeDo.develop"/>
              </div>
            </div>
          </Container>
        </div>
      </div>
    )
  } 
}

export default withTranslation()(SectionWhatWeDo);
