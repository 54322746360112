import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Container from '@material-ui/core/Container';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './SectionTrademarks.scss';

class SectionTrademarks extends Component  {
  
  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="content-trademark">
        <Container maxWidth="xl">
          <OwlCarousel
            className="owl-theme"
            data-aos="fade-right"
            data-aos-once="true"
            items={9}
            margin={50}
            responsive={{
              0: {
                items: 3,
                loop: true,
                slideBy: 3
              },
              480: {
                items: 3,
                loop: true,
                slideBy: 3
              },
              768: {
                items: 4,
                loop: true,
                slideBy: 4
              },
              1025: {
                items: 9
              }
            }}
          >
            <div className="item item-carrusel-trademark">
              <img src="./img/002_visa.svg" alt="visa"/>
            </div>
            <div className="item item-carrusel-trademark">
              <img src="./img/003_mast.svg" alt="masterCard"/>
            </div>
            <div className="item item-carrusel-trademark">
              <img src="./img/004_blomberg.svg" alt="bloomberg"/>
            </div>
            <div className="item item-carrusel-trademark">
              <img src="./img/005_forbes.svg" alt="forbes"/>
            </div>
            <div className="item item-carrusel-trademark">
              <img src="./img/006_MIT.svg" alt="MIT"/>
            </div>
            <div className="item item-carrusel-trademark">
              <img src="./img/007_dow.svg" alt="dow"/>
            </div>
            <div className="item item-carrusel-trademark">
              <img src="./img/008_TC.svg" alt="TC"/>
            </div>
            <div className="item item-carrusel-trademark">
              <img src="./img/009_dupont.svg" alt="dupont"/>
            </div>
            <div className="item item-carrusel-trademark">
              <img src="./img/010_corteva.svg" alt="corteva"/>
            </div>
          </OwlCarousel>
        </Container>
      </div>
    )
  } 
}

export default SectionTrademarks;
