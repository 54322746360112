import React from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ScrollToTop from './components/generals/scrollTop/scrollTop';
import NavBar from './components/generals/navBar/navBar';
import Home from './components/pages/home/Home';
import TermsAndConditions from './components/pages/termsAndConditions/TermsAndConditions';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div className="nav-bar">
          <NavBar />
        </div>
        <div>
          <Switch>
            <Route path="/terms-and-conditions">
              <TermsAndConditions />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
