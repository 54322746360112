const en = {
    get: 'Get a ',
    beluga: 'Beluga Pay',
    account: 'ACCOUNT',
    download: 'Download the app and signup for free'
};

const es = {
    get: 'Crea tu cuenta ',
    beluga: 'Beluga Pay',
    account: 'Regístrate',
    download: 'Descarga gratis nuestra aplicación'
};

module.exports = {
    en,
    es
};
