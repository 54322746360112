const en = {
    smallBusiness: 'Small business',
    enterprise: 'Enterprise',
    about: 'About',
    contact: 'Contact',
    pricing: 'Pricing',
    learn: 'Learn',
    getBelugapay: 'Get belugapay',
};

const es = {
   smallBusiness: 'Pequeños negocios',
   enterprise: 'Empresarial',
   about: 'Nosotros',
   contact: 'Contacto',
   pricing: 'Precios',
   learn: 'Aprende',
   getBelugapay: 'Obtener belugapay',
};

module.exports = {
   en,
   es
};