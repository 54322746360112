const en = {
    gold: 'THE GOLD ',
    standard: 'STANDARD IN PAYMENTS',
    beluga: 'BelugaPay ',
    works: 'works like a bank, but instead of having expensive real estate and tellers, ',
    focus: 'we focus on making technology work for you.',
    deposits: 'Instant Deposits',
    payments: 'Instant Payments',
    allInOne: 'All in one'
};

const es = {
    gold: 'Estándar de oro',
    standard: ' en pagos',
    beluga: 'BelugaPay ',
    works: 'funciona como un banco, pero en lugar de tener bienes raíces caras y cajeros, ',
    focus: 'nos enfocamos en que la tecnología trabaje para ti',
    deposits: 'Depósitos Instantáneos',
    payments: 'Pagos instantáneos',
    allInOne: 'Todo en uno'
};

module.exports = {
    en,
    es
};