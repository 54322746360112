import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './AllInOne.scss';

class AllInOne extends Component  {
  
  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="all-in-one">
        <div className="container-getpaid">
          <div className="container-info">
            <div
              className="title text-right light"
              data-aos="fade-up"
              data-aos-once="true">
                <span className="semi-bold">
                  <Trans i18nKey="allInOne.make" />
                </span> <br/>
                <Trans i18nKey="allInOne.track" />
                <br/>
                <Trans i18nKey="allInOne.offline" />
                <br/>
            </div>
          </div>
          <div
            className="content-imageGetpaid text-center"
            style={{
              backgroundImage: 'url("./img/027_Dash-8.png")'
            }}
            data-aos="fade-up"
            data-aos-once="true">
          </div>
        </div>
      </div>
    )
  } 
}

export default AllInOne;
