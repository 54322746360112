import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import ParticlesAnimation from './../particlesAnimation/ParticlesAnimation';

import InstantDeposits from './instantDeposits/InstantDeposits';
import InstantPayments from './instantPayments/InstantPayments';
import AllInOne from './allInOne/AllInOne';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './SectionSteps.scss';

class SectionSteps extends Component  {

  state = {
    page: 0
  };
  
  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  handleChangePage (event, newValue) {
    this.setState({
      page: newValue
    });
  };

  handleChangeIndex(index) {
    this.setState({
      page: index
    });
  };

  handleImageLoaded() {
    this.setState({
      showParticles: true
    });
  }

  render() {
    return (
      <div className="content-steps position-relative">
        { this.state.showParticles ? <ParticlesAnimation /> : null }
          <div
            className="title text-center text-blue-light-beluga mb-5"
            data-aos="fade-up"
            data-aos-once="true">
            <span className="semi-bold">
               <Trans i18nKey="sectionSteps.gold" />
            </span> 
            <Trans i18nKey="sectionSteps.standard" />
          </div>
          <div
            className="container-steps"
            data-aos="fade-up"
            data-aos-once="true">
            <AppBar
              position="static"
              className="appbar-steps">
              <Tabs
                variant="fullWidth"
                value={this.state.page}
                onChange={this.handleChangePage.bind(this)}
              >
                <Tab label={<Trans i18nKey="sectionSteps.deposits"/>} id={`nav-tabpanel-0`} className="big-font" />
                <Tab label={<Trans i18nKey="sectionSteps.payments"/>} id={`nav-tabpanel-1`} className="big-font" />
                <Tab label={<Trans i18nKey="sectionSteps.allInOne"/>} id={`nav-tabpanel-2`} className="big-font"/>
              </Tabs>
            </AppBar>
            <SwipeableViews
                className="containerSwipeableViews"
                index={this.state.page}
                onChangeIndex={this.handleChangeIndex.bind(this)}
              >
              <div value={this.state.page} index={0}>
                <div className="container-page">
                  <InstantDeposits/>
                </div>
              </div>
              <div value={this.state.page} index={1}>
                <div className="container-page">
                  <InstantPayments/>
                </div>
              </div>
              <div value={this.state.page} index={2}>
                <div className="container-page">
                  <AllInOne/>
                </div>
              </div>
            </SwipeableViews>
          </div>
        <div className="content-sectionFocusTechnology">
          <Container>
            <div
              className="text-focusTechnology big-font text-center light"
              data-aos="fade-up"
              data-aos-once="true">
                <span className="semi-bold big-font">
                  <Trans i18nKey="sectionSteps.beluga" />
                </span>
                <span className="big-font">
                  <Trans i18nKey="sectionSteps.works" />
                </span>
                <span className="semi-bold big-font">
                  <Trans i18nKey="sectionSteps.focus" />
                </span>
            </div>
          </Container>
        </div>
      </div>
    )
  } 
}

export default withTranslation()(SectionSteps);
