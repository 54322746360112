const en = {
    email: 'Email',
    commentary: 'Message',
    contact: 'Contact',
    send: 'send'
};

const es = {
    email: 'Correo',
    commentary: 'Comentario',
    contact: 'Contacto',
    send: 'Enviar'
};

module.exports = {
    en,
    es
};
