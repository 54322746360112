const en = {
    what: 'What ',
    we: 'we do',
    banking: 'Banking in Latin America is hard and complex',
    make: 'We make it simple and human',
    makeEasy: 'We make it easy by building easy to use technology with no hidden fees.',
    develop: 'We develop mobile point of sales and ecommerce solutions to get your business paid.'
};

const es = {
    what: 'Lo ',
    we: 'que hacemos',
    banking: 'El sistema bancario en Latinoamerica es difícil y complejo',
    make: 'Nosotros lo hacemos simple y humano',
    makeEasy: 'Lo facilitamos construyendo tecnología intuitiva y sin cargos ocultos',
    develop: 'Desarrollamos puntos de venta móviles y soluciones de e-commerce'
};

module.exports = {
    en,
    es
};