const en = {
   launchYourDigitalBank: 'Launch your <br/> digital bank <br/> in 30 days',
   requestDemo: 'Request demo'
};

const es = {
    launchYourDigitalBank: 'Lanza tu <br/> banco digital <br/> en 30 días',
    requestDemo: 'Solicitar demo'
};

module.exports = {
    en,
    es
};