import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './SectionDownload.scss';

class SectionDownload extends Component  {
  
  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div
        className="section-between section-download"
        style={{
          backgroundImage: 'url("./img/042_back-8.png")'
        }}
        >
        <Container>
          <div className="container-info-download text-center text-white">
            <div>
              <div
                className="text-important light"
                data-aos="fade-up"
                data-aos-once="true">
                <Trans i18nKey="sectionDownload.get" />
                <span className="semi-bold">
                  Beluga Pay
                </span>
                <a className="anchor" href="https://dashboard.belugapay.com/" target="_blank" rel="noopener noreferrer">
                  <Button className="btn-account ml-4" variant="outlined">
                    <Trans i18nKey="sectionDownload.account" />
                  </Button>
                </a>
              </div>
              <div
                className="subtitle-contact light mt-2"
                data-aos="fade-up"
                data-aos-once="true">
                <Trans i18nKey="sectionDownload.download" />
              </div>
              <div
                className="container-btn-apps mt-4"
                data-aos="fade-up"
                data-aos-once="true">
                  <a href="https://apps.apple.com/app/beluga-pay/id1290312961" target="_blank" rel="noopener noreferrer">
                    <img className="icon-app" src="./img/044_logoIOS-8.png" alt="appStore"/>
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.belugapay.app" target="_blank" rel="noopener noreferrer">
                    <img className="icon-app" src="./img/045_logandroid-8.png" alt="playStore"/>
                  </a>
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  } 
}

export default withTranslation()(SectionDownload);
