import sectionHeader from './sections/sectionHeader';
import menu from './sections/menu';
import sectionDemo from './sections/sectionDemo';
import sectionWhatWeDo from './sections/sectionWhatWeDo';
import sectionSteps from './sections/sectionSteps';
import instantDeposits from './sections/instantDeposits';
import instantPayments from './sections/instantPayments';
import allInOne from './sections/allInOne';
import sectionDownload from './sections/sectionDownload';
import sectionContact from './sections/sectionContact';
import footer from './sections/footer';

export default {
  en: {
    menu: menu.en,
    sectionHeader: sectionHeader.en,
    sectionDemo: sectionDemo.en,
    sectionWhatWeDo: sectionWhatWeDo.en,
    sectionSteps: sectionSteps.en,
    instantDeposits: instantDeposits.en,
    instantPayments: instantPayments.en,
    allInOne: allInOne.en,
    sectionDownload: sectionDownload.en,
    sectionContact: sectionContact.en,
    footer: footer.en
  },

  es: {
    menu: menu.es,
    sectionHeader: sectionHeader.es,
    sectionDemo: sectionDemo.es,
    sectionSteps: sectionSteps.es,
    instantDeposits: instantDeposits.es,
    instantPayments: instantPayments.es,
    allInOne: allInOne.es,
    sectionWhatWeDo: sectionWhatWeDo.es,
    sectionDownload: sectionDownload.es,
    sectionContact: sectionContact.es,
    footer: footer.es    
  }
}
