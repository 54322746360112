import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
import i18n from './../../../../i18n';
import { withTranslation, Trans } from 'react-i18next';

import Button from '@material-ui/core/Button';
import './Menu.scss';

class Menu extends Component {

  changeLanguage(lng) {
    i18n.changeLanguage(lng);
  }

  render() {
    return (
      <div className={`container-menu ${this.props.mode}`}>
        <div className="container-logo">
          <Link to="/">
            <img className="image-logo" src="./img/023_belugaprinciopal.svg" alt="logoBeluga"/>
          </Link>
          
        </div>
        <div className="container-bts">
          {/* <div className="btn-menu">
            <Button>
              <Trans i18nKey="menu.smallBusiness" />
            </Button>
          </div>
          <div className="btn-menu">
            <Button>
              <Trans i18nKey="menu.enterprise" />
            </Button>
          </div>
          <div className="btn-menu">
            <Button>
              <Trans i18nKey="menu.about" />
            </Button> 
          </div>
          <div className="btn-menu">
            <Button>
              <Link to="contact" smooth={true} duration={500} onClick={() => this.props.closeToggleDrawer()}>
                <Trans i18nKey="menu.contact" />
              </Link>
            </Button> 
          </div>
          <div className="btn-menu">
            <Button>
              <Trans i18nKey="menu.pricing" />
            </Button> 
          </div>
          <div className="btn-menu">
            <Button>
              <Trans i18nKey="menu.learn" />
            </Button> 
          </div> */}
          <div className="btn-menu">
            <a href="https://dashboard.belugapay.com/" target="_blank" rel="noopener noreferrer">
              <Button>
                <Trans i18nKey="menu.getBelugapay" />
              </Button> 
            </a>
          </div>
          <div className="container-btn-flags">
            <Button className="expand-link" onClick={() => this.changeLanguage('es')}>
              <img className="icon-flag flag-black" src="./img/038_FlagMx.svg" alt="blackMxFlag"/>
              <img className="icon-flag flag-color" src="./img/FlagMEXICO_color.svg" alt="colorMxFlag"/>
            </Button>
            <Button className="expand-link" onClick={() => this.changeLanguage('en')}>
              <img className="icon-flag flag-black" src="./img/039_FlagEU_1.svg" alt="blackUSFlag"/>
              <img className="icon-flag flag-color" src="./img/FlagEUA_color.svg" alt="colorUSFlag"/>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Menu);