import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withTranslation, Trans } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './Footer.scss';

class Footer extends Component {

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <footer>
        <Container maxWidth="xl" className="footer-vert-gap" id="txt-white">
          <Grid container spacing={1} className="center">
            <Grid item sm={12} xs={12}>
              <div className="container-footer-info">
                <div>
                  <img src="./img/044_logo.svg" className="image-logo" alt="logoBeluga" />
                </div>
                <div className="footer-mail">
                  <a href="mailto:support@belugapay.com" rel="noopener noreferrer" target="_blank" className="link"> 
                    support@belugapay.com
                  </a>
                </div>
                <div className="footer-follow">
                  <Trans i18nKey="footer.follow" />
                </div>
                <div>
                  <a target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/BelugaPay/">
                    <img src="./img/018_facebook.svg" className="footer-social" alt="facebook" />
                  </a>
                  <a target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/beluga_pay/">
                    <img src="./img/019_instagram.svg" className="footer-social" alt="instagram" />
                  </a>
                  <a target="_blank" rel="noreferrer noopener" href="https://t.me/belugapay">
                    <img src="./img/020_telegram.svg" className="footer-social" alt="telegram" />
                  </a>
                  <a target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/company/25070652/">
                    <img src="./img/021_inkedin.svg" className="footer-social" alt="linkedin" />
                  </a>
                  <a target="_blank" rel="noreferrer noopener" href="https://twitter.com/BelugaPay">
                    <img src="./img/022_Twitter.svg" className="footer-social" alt="twitter" />
                  </a>
                </div>
              </div>
            </Grid>
            {/* <Grid item sm={7} xs={12}>
              <Grid container>
                <Grid item xs>
                  <span className="list list-head">
                    <Trans i18nKey="footer.about" />
                  </span>
                  <Grid item className="list">
                    <Trans i18nKey="footer.careers" />
                  </Grid>
                </Grid>
                <Grid item xs>
                  <span className="list list-head">
                    <Trans i18nKey="footer.products"/>
                  </span>
                  <Grid item className="list">
                    <Trans i18nKey="footer.mercants" />
                  </Grid>
                  <Grid item className="list">
                    <Trans i18nKey="footer.restaurants" />
                  </Grid>
                  <Grid item className="list">
                    <Trans i18nKey="footer.crypto" />
                  </Grid>
                  <Grid item className="list">
                    <Trans i18nKey="footer.roadmap" />
                  </Grid>
                </Grid>
                <Grid item xs>
                  <span className="list list-head">
                    <Trans i18nKey="footer.pricing"/>
                  </span>
                </Grid>
                <Grid item xs>
                  <span className="list list-head">
                   <Trans i18nKey="footer.contacts"/>
                  </span>
                </Grid>
                <Grid item xs>
                  <span className="list list-head">
                    <Trans i18nKey="footer.faq"/>
                  </span>
                </Grid>
                <Grid item xs>
                  <span className="list list-head">
                    <Trans i18nKey="footer.legal"/>
                  </span>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Container>

        <Container maxWidth="xl">
          <div className="footer text-center light">
            © 2019 Beluga Pay International Pte Ltd <Link to="/terms-and-conditions">Terms and conditions & Privacy Policy.</Link>
          </div>
        </Container>
      </footer>
    )
  }
}

export default withTranslation()(Footer);
